import Stripe from 'stripe';
import { loadConnectAndInitialize } from "@stripe/connect-js";
import { Config } from '../util/Config';
import { I18nUtil } from './I18nUtil';
import { AppError, Customer, Log } from 'base.f6st.com';

export class StripeUtil {
  private static createStripeInstance(customer?: Customer): Stripe {
    return new Stripe(Config.getStripeSecretKey(), {
      apiVersion: '2024-04-10',
    });
  }

  static async getPaymentMethodType(paymentMethodId: string, customer: Customer): Promise<string> {
    const stripe = this.createStripeInstance(customer);
    const paymentMethod = await stripe.paymentMethods.retrieve(paymentMethodId, {
      stripeAccount: customer.businessSettings.connectedAccountId
    });
    return paymentMethod.type;
  }

  static async createPaymentIntentConnect(
    totalAmount: number,
    customer: Customer
  ): Promise<string> {
    let paymentMethods = I18nUtil.getSupportedPaymentMethods(customer.businessSettings.countryCode);
    const stripe = this.createStripeInstance(customer);

    // Calculate the tip amount and total amount
    let totalAmountCents = totalAmount * 100; // works in cents
    totalAmountCents = Math.trunc(totalAmountCents);

    const applicationFeeAmount = Math.round(totalAmountCents * (Config.APPLICATION_FEE_PERCENTAGE / 100));
    const connectedAccountId = customer.businessSettings.connectedAccountId;

    const currencyCode = I18nUtil.getSupportedCountry(customer.businessSettings.countryCode).currencyCode;

    const paymentIntent = await stripe.paymentIntents.create({
      amount: totalAmountCents,
      currency: currencyCode,
      payment_method_types: paymentMethods,
      application_fee_amount: applicationFeeAmount
    }, {
      stripeAccount: connectedAccountId,
    });

    Log.debug("Payment intent created", paymentIntent);

    if (!paymentIntent.client_secret) {
      throw new AppError("Failed to create payment intent: no client secret returned");
    }

    return paymentIntent.client_secret;
  }

  static async createAccountSession(customer: Customer): Promise<{ accountId: string, clientSecret: string }> {
    const stripe = this.createStripeInstance(customer);
    const account = await stripe.accounts.create({
      controller: { stripe_dashboard: { type: "none" } },
      capabilities: {
        card_payments: { requested: true },
        transfers: { requested: true },
      },
      country: customer.businessSettings.countryCode,
    });

    const accountSession = await stripe.accountSessions.create({
      account: account.id,
      components: {
        account_onboarding: { enabled: true },
        account_management: { enabled: true },
        notification_banner: { enabled: true },
      },
    });

    return { accountId: account.id, clientSecret: accountSession.client_secret };
  }

  static useStripeConnect(clientSecret: string, customer: Customer) {
    const fetchClientSecret = async () => clientSecret;

    if (customer.businessSettings.connectedAccountId) {
      return loadConnectAndInitialize({
        publishableKey: Config.getStripePublicKey(),
        fetchClientSecret,
        appearance: {
          overlays: "drawer",
          variables: {
            colorPrimary: "#172339",
          },
        },
      });
    }
  }
}
