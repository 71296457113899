import React, { useMemo } from 'react';
import { Box, Divider, ListItemButton, List, ListItem, ListItemText } from '@mui/material';
import { useAppContext } from './AppProvider';
import { useTranslation } from "react-i18next";
import { ProductCategory, ProductFilter, ProductsTextsUtil } from 'base.f6st.com';

type NavigationProps = {
  toggleDrawer: (newOpen: boolean) => void
}

const FilterListItem: React.FC<{ filterToSet: ProductFilter, count: number, toggleDrawer: (newOpen: boolean) => void }> = ({ filterToSet, count, toggleDrawer }) => {
  const { productFilter: filter, setProductFilter: setFilter } = useAppContext()!;
  const customer = useAppContext().getCustomer();
  const { i18n } = useTranslation();
  const txt = useMemo(() => new ProductsTextsUtil(customer, i18n.language), [customer, i18n.language]);

  const handleClick = () => {
    setFilter(filterToSet);
    toggleDrawer(false);
  };

  return (
    <ListItemButton onClick={handleClick} disabled={filter && filter.id === filterToSet.id}>
      <ListItemText primary={`${txt.get(filterToSet.nameId)} (${count})`} />
    </ListItemButton>
  );
};

export const Navigation = ({ toggleDrawer }: NavigationProps) => {
  const { productFilter: filter, resetProductFilter: resetFilter } = useAppContext()!;
  const { t } = useTranslation();
  const customer = useAppContext().getCustomer();
  const products = customer.products;
  const { i18n } = useTranslation();
  const txt = useMemo(() => new ProductsTextsUtil(customer, i18n.language), [customer, i18n.language]);

  const countFilteredProductsInCategory = (category: ProductCategory, filterId?: string) => {
    return filterId
      ? category.products.filter(product => product.filterIds?.includes(filterId) ?? false).length
      : category.products.length;
  };
  
  const calculateTotalProducts = () => {
    return products.categories.reduce((total, category) => total + category.products.length, 0) || 0;
  };

  return (
    <Box sx={{ width: 250 }} p={2} role="presentation">
      <List>
        {products.categories.map((category: ProductCategory) => {
          const productCount = filter ?
            countFilteredProductsInCategory(category, filter.id) :
            category.products.length;

          return (
            <ListItem
              key={category.id}
              disablePadding
              onClick={() => {
                if (!filter || productCount > 0) {
                  const element = document.getElementById(category.id);
                  if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                  }
                  toggleDrawer(false);
                }
              }}
            >
              <ListItemButton disabled={filter && productCount === 0}>
                <ListItemText primary={`${txt.get(category.nameId)} (${productCount})`} />
              </ListItemButton>
            </ListItem>
          );
        })}
        <Divider />
        <List>
          {products.filters.map(filterToSet => (
            <FilterListItem
              key={filterToSet.id}
              filterToSet={filterToSet}
              count={products.categories.reduce((sum, cat) => sum + countFilteredProductsInCategory(cat, filterToSet.id), 0)}
              toggleDrawer={toggleDrawer}
            />
          ))}
          <ListItemButton onClick={() => { resetFilter(); toggleDrawer(false); }} disabled={!filter}>
            <ListItemText primary={`${t('navigation.showAll')} (${calculateTotalProducts()})`} />
          </ListItemButton>
        </List>
      </List>
    </Box>
  );
}
