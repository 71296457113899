import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Typography,
  Box,
  Link,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { SecondaryButton } from './SecondaryButton';
import { StandardButton } from './StandardButton';
import { MailClient } from '../api/MailClient';
import { CallerType } from 'base.f6st.com';

interface FooterProps {
  stick?: boolean;
  terms: string;
  feedback: string;
}

export const Footer: React.FC<FooterProps> = ({ stick, terms, feedback }) => {
  const [open, setOpen] = useState(false);
  const [attachScreenshot, setAttachScreenshot] = useState(false);

  const formik = useFormik({
    initialValues: {
      feedback: '',
      email: '',
      screenshot: null as File | null,
      screenshotBase64: ''
    },
    validationSchema: Yup.object({
      feedback: Yup.string().required('Feedback is required'),
      email: Yup.string().email('Invalid email address')
    }),
    onSubmit: async (values, { resetForm }) => {
      const screenshotBase64 = values.screenshotBase64.split(',')[1]; 
      const subject = 'Customer Feedback';
      const body = `Feedback: ${feedback}\nEmail: ${values.email}`
      await MailClient.sendFeedback(CallerType.ADMIN, subject, body, screenshotBase64, values.screenshot?.name); // TODO set CallerType dynamically
      toast.success("Thank you for your Feedback.");
      resetForm();
      setOpen(false);
    }
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size <= 1048576 && (file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          formik.setFieldValue('screenshot', file);
          formik.setFieldValue('screenshotBase64', reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Only JPEG and PNG files under 1MB are allowed.");
      }
    }
  };

  const shouldStick = stick && (terms === 'true' || feedback === 'true');
  const showTerms = terms === 'true';
  const showFeedback = feedback === 'true';

  const linkStyles = {
    fontSize: 15,
    padding: '8px 16px',
    margin: '0 8px',
    borderRadius: '36px',
    height: '40px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.3s ease',
    textDecoration: 'none',
    color: 'rgba(23, 35, 57, 0.3)',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
    '&:hover': {
      backgroundColor: '#172339',
      color: '#faf8f6',
    },
    '&.active': {
      backgroundColor: '#e84b4bee',
      color: '#172339',
      border: '1px solid #172339',
    },
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        sx={{
          position: shouldStick ? 'fixed' : 'static',
          bottom: shouldStick ? '20px' : 'unset',
          left: shouldStick ? '50%' : 'unset',
          transform: shouldStick ? 'translateX(-50%)' : 'unset',
          zIndex: 1000,
          mt: shouldStick ? 0 : '25px',
          mb: shouldStick ? 0 : '25px'
        }}
      >
        {showFeedback && (
          <Link
            sx={linkStyles}
            onClick={handleOpen}
          >
            Feedback
          </Link>
        )}
        {showTerms && (
          <Link
            href="https://f6st.com/terms"
            target="_blank"
            sx={linkStyles}
          >
            Terms & Privacy Policy
          </Link>
        )}
        <Link
          href="https://f6st.com/"
          target="_blank"
          sx={linkStyles}
        >
          F6ST.com
        </Link>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography variant="h6" component="div">Your Feedback</Typography>
          <IconButton onClick={handleClose} style={{ position: "absolute", right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Typography variant="body1" gutterBottom>
              Please describe any feedback or issues in detail. If we may contact you for more information, include your email address.
            </Typography>
            <TextField
              id="feedback"
              name="feedback"
              label="Feedback"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              margin="normal"
              value={formik.values.feedback}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.feedback && Boolean(formik.errors.feedback)}
              helperText={formik.touched.feedback && formik.errors.feedback}
            />
            <TextField
              id="email"
              name="email"
              label="Email (optional)"
              variant="outlined"
              fullWidth
              margin="normal"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={attachScreenshot}
                  onChange={() => setAttachScreenshot(!attachScreenshot)}
                  color="primary"
                />
              }
              label="Attach screenshot"
            />
            {attachScreenshot && (
              <>
                <input
                  accept=".jpg,.jpeg,.png"
                  style={{ display: 'none' }}
                  id="file-upload"
                  type="file"
                  onChange={handleFileChange}
                />
                <label htmlFor="file-upload">
                  <Box
                    sx={{
                      border: '1px dashed grey',
                      padding: '8px',
                      cursor: 'pointer',
                      display: 'inline-block',
                      mt: 1,
                      textAlign: 'center'
                    }}
                  >
                    Click to upload (JPEG or PNG, max 1MB)
                  </Box>
                </label>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <SecondaryButton action={handleClose} text="Cancel" style={{ width: '130px' }} />
            <StandardButton text="Send" action={formik.submitForm} style={{ width: '130px' }} />
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};
