import { useState } from 'react';
import Nav from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/MenuOutlined';
import { Button, Drawer } from '@mui/material';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { LanguageSelector } from 'common.f6st.com';
import { Navigation } from './Navigation';
import { useAppContext } from '../components/AppProvider';

type CustomAppBarProps = {
  backButton?: boolean
}

export default function CustomAppBar({ backButton }: CustomAppBarProps) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const { getCustomer, loadLanguage } = useAppContext(); // Destructure needed functions from context

  const toggleDrawer = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  const handleLanguageChange = async (newLanguage: string) => {
    const customer = getCustomer();
    await loadLanguage(customer, newLanguage);
  };

  return (
    <Nav position="sticky">
      <Toolbar sx={{ bgcolor: '#faf8f6', paddingLeft: backButton ? 0 : 2 }}>
        {backButton ? (
          <Button
            sx={{
              color: '#172339',
              fontWeight: 700,
              fontSize: '16px',
              border: '1px solid #172339',
              padding: 0,
              margin: 1,
              borderRadius: '24px',
              width: '80px',
              height: '45px',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: '#172339',
                color: '#faf8f6',
              }
            }}
            onClick={() => navigate("/")}
          >
            {t('buttons.back')}
          </Button>
        ) : (
          <IconButton
            size="large"
            aria-label="menu"
            sx={{
              color: 'black',
              marginLeft: '-16px',
              border: 0,
              padding: 0,
              borderRadius: 0,
              width: '56px',
              height: '56px',
            }} // '&:hover': { backgroundColor: '#fa3a1ecc' },
            onClick={() => toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
        )}

        <div style={{ flexGrow: 1 }} />

        <LanguageSelector small i18n={i18n} onLanguageChange={handleLanguageChange} />

        <Drawer open={open} onClose={() => toggleDrawer(false)}>
          <Navigation toggleDrawer={toggleDrawer} />
        </Drawer>
      </Toolbar>
    </Nav>
  );
}
