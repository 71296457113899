import { Customer, Order, OrderUtil, OrderItem, PaymentStatus } from "base.f6st.com";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";

export type BusinessReceiptData = {
  companyName: string;
  companyAddress: string;
  vatID?: string;
};

export class ReceiptPdfUtil {
  static async generateReceiptPDF(
    order: Order,
    customer: Customer,
    translatedLabels: Record<string, string>,
    businessReceiptData?: BusinessReceiptData
  ): Promise<Uint8Array> {
    const pdfDoc = await PDFDocument.create();
    const pageWidth = 227; // Approx. 80mm in points
    const pageHeight = 600;
    const page = pdfDoc.addPage([pageWidth, pageHeight]);

    const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    const regularFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

    const { businessName, address, languageCodePrimary, vatID, phoneNumber } = customer.businessSettings;
    const { street, streetNumber, zip, city } = address;

    const margin = 10;
    let currentY = pageHeight - margin;

    // Add gap at the top
    currentY -= 20;

    // Business details
    const businessAddress = `${street} ${streetNumber}, ${zip} ${city}`;
    page.drawText(businessName, { x: margin, y: currentY, size: 10, font: boldFont, color: rgb(0, 0, 0) });
    currentY -= 12;
    page.drawText(businessAddress, { x: margin, y: currentY, size: 10, font: regularFont, color: rgb(0, 0, 0) });
    currentY -= 12;
    page.drawText(`${translatedLabels['vatID']}: ${vatID}`, { x: margin, y: currentY, size: 10, font: regularFont, color: rgb(0, 0, 0) });
    currentY -= 12;
    page.drawText(`${translatedLabels['phone']}: ${phoneNumber}`, { x: margin, y: currentY, size: 10, font: regularFont, color: rgb(0, 0, 0) });
    currentY -= 15;

    // Order details
    const orderDate = new Date(order.date);
    const formattedDate = orderDate.toLocaleDateString(languageCodePrimary);
    const formattedTime = orderDate.toLocaleTimeString(languageCodePrimary);
    page.drawText(`${translatedLabels['receiptNumber']}: ${order.id}`, { x: margin, y: currentY, size: 10, font: regularFont, color: rgb(0, 0, 0) });
    currentY -= 12;
    page.drawText(`${translatedLabels['date']}: ${formattedDate} ${formattedTime}`, { x: margin, y: currentY, size: 10, font: regularFont, color: rgb(0, 0, 0) });
    currentY -= 12;

    // Payment details
    const paymentText = order.paymentStatus === PaymentStatus.PAY_IN_PERSON ? translatedLabels['laterPaymentInPerson'] : order.paymentMethod;
    page.drawText(`${translatedLabels['paymentMethod']}: ${paymentText}`, { x: margin, y: currentY, size: 10, font: regularFont, color: rgb(0, 0, 0) });
    currentY -= 15;

    // Add gap after payment method
    currentY -= 10;

    // Item headers
    page.drawText(translatedLabels['quantity'], { x: margin, y: currentY, size: 10, font: boldFont, color: rgb(0, 0, 0) });
    page.drawText(translatedLabels['item'], { x: margin + 30, y: currentY, size: 10, font: boldFont, color: rgb(0, 0, 0) });
    page.drawText(translatedLabels['price'], { x: margin + 130, y: currentY, size: 10, font: boldFont, color: rgb(0, 0, 0) });
    page.drawText(translatedLabels['vat'], { x: margin + 170, y: currentY, size: 10, font: boldFont, color: rgb(0, 0, 0) });
    currentY -= 15;

    // Items
    order.items.forEach((item: OrderItem) => {
      const productName = order.texts[item.product.nameId];
      const sizeName = item.sizeId ? order.texts[item.product.sizes?.find(size => size.id === item.sizeId)?.nameId || ''] : '';
      const itemPriceString = OrderUtil.getOrderItemTotalAmount(item, order.currency).amountString;

      page.drawText(`${item.quantity}`, { x: margin, y: currentY, size: 10, font: regularFont, color: rgb(0, 0, 0) });
      page.drawText(`${productName} ${sizeName ? `(${sizeName})` : ''}`, { x: margin + 30, y: currentY, size: 10, font: regularFont, color: rgb(0, 0, 0) });
      page.drawText(`${itemPriceString}`, { x: margin + 130, y: currentY, size: 10, font: regularFont, color: rgb(0, 0, 0) });
      page.drawText(`${item.vatPercentage.toFixed(2)}%`, { x: margin + 170, y: currentY, size: 10, font: regularFont, color: rgb(0, 0, 0) });

      currentY -= 12;

      // Extras
      const extras = item.extraIds?.map(extraId =>
        item.product.extras.find(extra => extra.id === extraId)
      ).filter(Boolean);

      if (extras && extras.length > 0) {
        extras.forEach(extra => {
          if (extra) {
            const extraAmountString = OrderUtil.getProductExtraAmount(extra, order.currency).amountString;
            page.drawText(`+ ${order.texts[extra.nameId]} (${extraAmountString})`, { x: margin + 30, y: currentY, size: 10, font: regularFont, color: rgb(0, 0, 0) });
            currentY -= 12;
          }
        });
      }
    });

    // Totals
    const { totalString, netString, vatString, tipString, discountString, serviceFeeString, deliveryFeeString, serviceFee, tip, discount } = OrderUtil.getOrderAmount(order);

    currentY -= 10;
    page.drawText(`${translatedLabels['netTotal']}:`, { x: margin, y: currentY, size: 10, font: boldFont, color: rgb(0, 0, 0) });
    page.drawText(`${netString}`, { x: margin + 100, y: currentY, size: 10, font: regularFont, color: rgb(0, 0, 0) });

    currentY -= 12;
    page.drawText(`${translatedLabels['vat']}:`, { x: margin, y: currentY, size: 10, font: boldFont, color: rgb(0, 0, 0) });
    page.drawText(`${vatString}`, { x: margin + 100, y: currentY, size: 10, font: regularFont, color: rgb(0, 0, 0) });

    // Discounts
    if (order.discountPercentage && discount > 0) {
      currentY -= 12;
      page.drawText(`${translatedLabels['discount']}: ${order.discountPercentage}%`, { x: margin, y: currentY, size: 10, font: regularFont, color: rgb(0, 0, 0) });
      page.drawText(`-${discountString}`, { x: margin + 100, y: currentY, size: 10, font: regularFont, color: rgb(0, 0, 0) });
    }

    // Additional charges and fees
    if (order.deliveryFee) {
      currentY -= 12;
      page.drawText(`${translatedLabels['deliveryFee']}:`, { x: margin, y: currentY, size: 10, font: boldFont, color: rgb(0, 0, 0) });
      page.drawText(`${deliveryFeeString}`, { x: margin + 100, y: currentY, size: 10, font: regularFont, color: rgb(0, 0, 0) });
    }

    if (serviceFee > 0) {
      currentY -= 12;
      page.drawText(`${translatedLabels['serviceFee']}:`, { x: margin, y: currentY, size: 10, font: boldFont, color: rgb(0, 0, 0) });
      page.drawText(`${serviceFeeString}`, { x: margin + 100, y: currentY, size: 10, font: regularFont, color: rgb(0, 0, 0) });
    }

    if (order.tipPercentage && tip > 0) {
      currentY -= 12;
      page.drawText(`${order.tipPercentage}% ${translatedLabels['tip']}:`, { x: margin, y: currentY, size: 10, font: boldFont, color: rgb(0, 0, 0) });
      page.drawText(`${tipString}`, { x: margin + 100, y: currentY, size: 10, font: regularFont, color: rgb(0, 0, 0) });
    }

    // Total
    currentY -= 12;
    page.drawText(`${translatedLabels['total']}:`, { x: margin, y: currentY, size: 10, font: boldFont, color: rgb(0, 0, 0) });
    page.drawText(`${totalString}`, { x: margin + 100, y: currentY, size: 10, font: regularFont, color: rgb(0, 0, 0) });

    // Delivery information
    if (order.deliveryDetails && order.deliveryDetails.fullName.trim().length > 0) {
      const { fullName, phoneNumber: deliveryPhone, deliveryInstructions, address: deliveryAddress } = order.deliveryDetails;
      const deliveryFormattedAddress = `${deliveryAddress.street} ${deliveryAddress.streetNumber}, ${deliveryAddress.zip} ${deliveryAddress.city}`;

      currentY -= 20;
      page.drawText(`${translatedLabels['deliveryInfo']}:`, { x: margin, y: currentY, size: 10, font: boldFont, color: rgb(0, 0, 0) });

      currentY -= 12;
      page.drawText(`${translatedLabels['fullName']}: ${fullName}`, { x: margin, y: currentY, size: 10, font: regularFont, color: rgb(0, 0, 0) });

      currentY -= 12;
      page.drawText(`${translatedLabels['phone']}: ${deliveryPhone}`, { x: margin, y: currentY, size: 10, font: regularFont, color: rgb(0, 0, 0) });

      if (deliveryInstructions) {
        currentY -= 12;
        page.drawText(`${translatedLabels['deliveryInstructions']}: ${deliveryInstructions}`, { x: margin, y: currentY, size: 10, font: regularFont, color: rgb(0, 0, 0) });
      }

      currentY -= 12;
      page.drawText(`${translatedLabels['address']}: ${deliveryFormattedAddress}`, { x: margin, y: currentY, size: 10, font: regularFont, color: rgb(0, 0, 0) });
    }

    // Business receipt information
    if (businessReceiptData) {
      const { companyName, companyAddress, vatID: businessVatID } = businessReceiptData;

      currentY -= 20;
      page.drawText(`${translatedLabels['customerInfo']}:`, { x: margin, y: currentY, size: 10, font: boldFont, color: rgb(0, 0, 0) });

      currentY -= 12;
      page.drawText(`${translatedLabels['companyName']}: ${companyName}`, { x: margin, y: currentY, size: 10, font: regularFont, color: rgb(0, 0, 0) });

      currentY -= 12;
      page.drawText(`${translatedLabels['companyAddress']}: ${companyAddress}`, { x: margin, y: currentY, size: 10, font: regularFont, color: rgb(0, 0, 0) });

      if (businessVatID) {
        currentY -= 12;
        page.drawText(`${translatedLabels['vatID']}: ${businessVatID}`, { x: margin, y: currentY, size: 10, font: regularFont, color: rgb(0, 0, 0) });
      }
    }

    const pdfBytes = await pdfDoc.save();

    return pdfBytes;
  }
}
