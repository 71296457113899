import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Card, CardContent, CardMedia, Typography, Box, Chip, FormControl, FormControlLabel, FormGroup, Stack, Switch, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useTranslation } from "react-i18next";
import { useAppContext } from './AppProvider';
import { OrderItem, Product, ProductsTextsUtil, ProductExtra, OrderUtil, Size } from 'base.f6st.com';
import { I18nUtil, Config, StandardButton } from 'common.f6st.com';

interface ProductDetailsCardProps {
  orderItem: OrderItem;
  onDetailsChange: (orderItem: OrderItem) => void;
}

export const ProductDetailsCard: React.FC<ProductDetailsCardProps> = ({ orderItem, onDetailsChange }) => {
  const { t } = useTranslation();
  const product: Product = orderItem.product;
  const [quantity, setQuantity] = useState(orderItem.quantity);
  const { basket } = useAppContext()!;
  const customer = useAppContext().getCustomer();
  const currency = I18nUtil.getDisplayableCurrency(customer.businessSettings.countryCode);
  const products = customer.products;
  const { i18n } = useTranslation();
  const txt = useMemo(() => new ProductsTextsUtil(customer, i18n.language), [customer, i18n.language]);

  useEffect(() => {
    onDetailsChange(orderItem);
  }, [orderItem, onDetailsChange, customer]);

  const handleSizeChange = (
    event: React.MouseEvent<HTMLElement>,
    newSizeId: string | null
  ) => {
    if (newSizeId) {
      orderItem.sizeId = newSizeId;
      onDetailsChange(orderItem);
    }
  };

  const handleExtrasChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    if (checked) {
      orderItem.extraIds = [...(orderItem.extraIds || []), value];
    } else {
      orderItem.extraIds = (orderItem.extraIds || []).filter((id) => id !== value);
    }
    onDetailsChange(orderItem);
  }, [orderItem, onDetailsChange]);

  const increaseQuantity = () => {
    if (quantity < Config.MAX_PRODUCT_ORDER_QUANTITY) {
      orderItem.quantity = quantity + 1;
      setQuantity(orderItem.quantity);
      onDetailsChange(orderItem);
    }
  };

  const decreaseQuantity = () => {
    if (quantity > 1 || (quantity > 0 && basket.isInBasket(orderItem.id))) {
      orderItem.quantity = quantity - 1;
      setQuantity(orderItem.quantity);
      onDetailsChange(orderItem);
    }
  };

  const filterChips = useMemo(() => (
    orderItem.product.filterIds && products.filters && orderItem.product.filterIds.length > 0 && (
      <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
        {orderItem.product.filterIds.map((id, index) => (
          <Chip
            key={index}
            label={txt.get(products.filters.find((filter) => filter.id === id)?.nameId)}
          />
        ))}
      </Stack>
    )
  ), [orderItem.product.filterIds, products.filters, txt]);

  const extraControls = useMemo(() => (
    orderItem.product.extras && (
      <FormControl sx={{ alignSelf: 'flex-start', ':focus': { color: '#e84b4bee' } }} component="fieldset" variant="standard">
        <FormGroup>
          {orderItem.product.extras.map((extra: ProductExtra) => (
            <FormControlLabel
              key={extra.id}
              control={
                <Switch
                  checked={(orderItem.extraIds || []).includes(extra.id)}
                  onChange={handleExtrasChange}
                  value={extra.id}
                  sx={{
                    '& .MuiSwitch-switchBase.Mui-checked': {
                      color: '#e84b4bee',
                      '& + .MuiSwitch-track': {
                        backgroundColor: '#e84b4bee'
                      },
                    },
                  }}
                />
              }
              label={`${txt.get(extra.nameId)} (+ ${OrderUtil.getProductExtraAmount(extra, currency).amountString})`}
            />
          ))}
        </FormGroup>
      </FormControl>
    )
  ), [orderItem.product.extras, orderItem.extraIds, handleExtrasChange, txt, currency]);

  return (
    <Card sx={{ margin: '24px auto 160px' }}>
      <Typography variant="h4" color="text.secondary" textAlign="center" marginTop={2}>
        {txt.get(product.nameId)}
      </Typography>
      <Box pt={2} px={2} display="flex" justifyContent="center">
        <CardMedia
          component="img"
          image={Config.getImageUrl(customer.id, product.imgId) || ''}
          alt={txt.get(product.nameId)}
          sx={{
            maxHeight: 600,
            maxWidth: 600,
            objectFit: 'contain'
          }}
        />
      </Box>

      <CardContent>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Typography variant="body2" color="text.secondary" textAlign="center">
            {txt.get(product.descriptionId)}
          </Typography>

          {filterChips}

          {product.sizes && product.sizes.length > 1 && (
            <ToggleButtonGroup
              color="warning"
              size="small"
              value={orderItem.sizeId}
              exclusive
              onChange={handleSizeChange}
              aria-label="Size"
              sx={{
                '& .MuiToggleButtonGroup-grouped': {
                  borderColor: 'black',
                  '&.Mui-selected, &.Mui-selected:hover': {
                    color: 'black',
                    backgroundColor: '#e84b4bee',
                    borderColor: 'black',
                  },
                  '&:hover': {
                    backgroundColor: 'rgba(255, 0, 0, 0.1)',
                  },
                },
              }}
            >
              {product.sizes.map((size: Size, i: number) => (
                <ToggleButton key={i} value={size.id}>{txt.get(size.nameId)}</ToggleButton>
              ))}
            </ToggleButtonGroup>
          )}

          {extraControls}

          <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
            <Typography variant='body1'>
              {t("detailsPage.quantity")}:
            </Typography>
            <Box display='flex' alignItems='center'>
              <StandardButton text='-' action={decreaseQuantity} style={{ width: '40px' }} />
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                sx={{
                  fontSize: 18,
                  width: '40px',
                  height: '40px',
                }}
              >
                {quantity}
              </Box>
              <StandardButton text='+' action={increaseQuantity} style={{ width: '40px' }} />
            </Box>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};
