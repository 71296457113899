// OrdersPage.tsx
import { Box, Container, Grid, Typography, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CuystomAppBar from "../components/CustomAppBar";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useAppContext } from '../components/AppProvider';
import { OrderStorage } from "../components/OrderStorage";
import { useState } from "react";
import { OrderInfoDialog } from "../components/OrderInfoDialog";
import { ProductCard, CardUseCase } from "../components/ProductCard";
import { Order, OrderItem } from "base.f6st.com";
import { StandardButton } from "common.f6st.com";

export const OrdersPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const customer = useAppContext().getCustomer();
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const orders = OrderStorage.get(24, customer.id);

  const handleInfoClick = (order: Order) => {
    setSelectedOrder(order);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedOrder(null);
  };

  return (
    <>
      <CuystomAppBar backButton />
      <Container style={{ marginBottom: '120px' }}>
        {
          orders.map((order: Order) => (
            <Box key={order.id} mt={2} display='flex' flexDirection='column' gap={2}>
              <Box display="flex" alignItems="center">
                <Typography variant="h4" component="div">
                  #{order.id}
                </Typography>
                <IconButton onClick={() => handleInfoClick(order)} sx={{ marginLeft: 1 }}>
                  <InfoIcon />
                </IconButton>
              </Box>
              <Grid container spacing={2} columns={12}>
                {
                  order.items.map((orderItem: OrderItem) => (
                    <ProductCard key={orderItem.id} product={orderItem.product} orderItem={orderItem} useCase={CardUseCase.ORDERPAGE} />
                  ))
                }
              </Grid>
            </Box>
          ))
        }

        <StandardButton
          text={t('buttons.newOrder')}
          action={() => navigate('/')}
          style={{ width: '80%', height: '56px', position: 'fixed', bottom: '20px', left: '50%', transform: 'translateX(-50%)', backgroundColor: '#e84b4bee' }}
        />

      </Container>

      {selectedOrder && (
        <OrderInfoDialog
          open={dialogOpen}
          order={selectedOrder}
          onClose={handleCloseDialog}
        />
      )}
    </>
  );
};