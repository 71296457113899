import { useNavigate } from "react-router";
import { Box, Container, Grid, Typography } from "@mui/material";
import CustomAppBar from "../components/CustomAppBar";
import { useTranslation } from "react-i18next";
import { useAppContext } from '../components/AppProvider';
import { useEffect, useMemo, useState } from "react";
import { OrderStorage } from "../components/OrderStorage";
import { ProductCard, CardUseCase } from "../components/ProductCard";
import { ProductsTextsUtil, Product, ProductUtil, ProductCategory } from "base.f6st.com";
import { StandardButton, Footer } from "common.f6st.com";

export const HomePage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { productFilter: filter, basket, firstAppOpening, setFirstAppOpening } = useAppContext();
  const customer = useAppContext().getCustomer();
  const { i18n } = useTranslation();
  const txt = useMemo(() => new ProductsTextsUtil(customer, i18n.language), [customer, i18n.language]);

  const [basketChangeTrigger, setBasketChangeTrigger] = useState(false);

  useEffect(() => {
    if (firstAppOpening) {
      // Check for orders within the specified hours and navigate to /orders if any are found
      const orders = [];
      OrderStorage.get(3, customer.id);
      if (orders.length > 0) {
        navigate('/orders');
      }
      // Set the flag to indicate that the homepage has been visited
      setFirstAppOpening(false);
    }
  }, [firstAppOpening, setFirstAppOpening, navigate, customer]);

  // Function to filter products based on the selected filter
  const filterProducts = (products: Product[]) => {
    return filter ? products.filter(product => product.filterIds?.includes(filter.id)) : products;
  };

  // Function to handle basket change
  const handleBasketChange = () => {
    setBasketChangeTrigger(!basketChangeTrigger);
  };

  // Check if there are no products and show the message
  if (!ProductUtil.isCategories(customer.products)) {
    return (
      <Container>
        <Typography variant="h4" align="center" sx={{ mt: 4 }}>
          No products have been configured
        </Typography>
      </Container>
    );
  }

  if (!customer.orderSettings.orderingOpen) {
    return (
      <Container>
        <Typography variant="h4" align="center" sx={{ mt: 4 }}>
          Ordering is closed
        </Typography>
      </Container>
    );
  }

  return (
    <>
      <CustomAppBar />

      <Container>
        <Box display='flex' flexDirection='column' gap={2} pb={basket.isEmpty() ? 2 : 12}>
          {
            customer.products.categories.map((category: ProductCategory) => {
              const filteredProducts = filterProducts(category.products);
              if (filteredProducts.length === 0) return null;

              return (
                <Box mt={2} display='flex' flexDirection='column' gap={2} key={category.id} id={category.id}>
                  <Typography variant="h4" component="div">
                    {txt.get(category.nameId)}
                  </Typography>
                  <Grid container spacing={2} columns={12}>
                    {filteredProducts.map((product) => (
                      <ProductCard 
                        key={product.id} 
                        product={product} 
                        useCase={CardUseCase.HOMEPAGE} 
                        onBasketChange={handleBasketChange} // Pass the callback to ProductCard
                      />
                    ))}
                  </Grid>
                </Box>
              );
            })
          }
        </Box>
        {
          basket.isEmpty() ? null :
            <StandardButton
              text={`${t('buttons.basket')} (${basket.getBasketSize()})`}
              action={() => navigate('/basket')}
              style={{ width: '80%', height: '56px', position: 'fixed', bottom: '20px', left: '50%', transform: 'translateX(-50%)', backgroundColor: '#e84b4bee' }}
              disabled={basket.isEmpty()}
            />
        }
        {
          basket.isEmpty() &&  <Footer terms="true" feedback="true" />
        }
      </Container>
    </>
  );
}
