import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { AppError, Log } from 'base.f6st.com';

export enum MetricSystem {
  KM = 'KM',
  MILES = 'Miles'
}


export class I18nUtil {
  // countries supported by F6ST
  public static getSupportedCountries(): CountryData[] {
    return [
      {
        countryCode: "DE",
        languageCodes: ["de"],
        vatDefault: 7,
        currencyCode: "EUR",
        additionalPaymentMethods: ['giropay'],
        openForRegistration: true
      },
      {
        countryCode: "AT",
        languageCodes: ["de"],
        vatDefault: 10,
        currencyCode: "EUR",
      },
      {
        countryCode: "BE",
        languageCodes: ["nl", "fr", "de"],
        vatDefault: 12,
        openForRegistration: true,
        currencyCode: "EUR",
      },
      {
        countryCode: "ES",
        languageCodes: ["es"],
        vatDefault: 10,
        currencyCode: "EUR",
      },
      {
        countryCode: "FI",
        languageCodes: ["fi", "sv"],
        vatDefault: 14,
        currencyCode: "EUR",
      },
      {
        countryCode: "FR",
        languageCodes: ["fr"],
        vatDefault: 10,
        currencyCode: "EUR",
      },
      {
        countryCode: "GR",
        languageCodes: ["el"],
        vatDefault: 13,
        currencyCode: "EUR",
      },
      {
        countryCode: "IE",
        languageCodes: ["en"], // 'ga' (Irish) is not supported by ChatGPT
        vatDefault: 9,
        currencyCode: "EUR",
      },
      {
        countryCode: "IT",
        languageCodes: ["it"],
        vatDefault: 10,
        currencyCode: "EUR",
      },
      {
        countryCode: "LU",
        languageCodes: ["fr", "de"], // 'lb' (Luxembourgish) is not supported by ChatGPT
        vatDefault: 8,
        currencyCode: "EUR",
      },
      {
        countryCode: "MT",
        languageCodes: ["en"], // 'mt' (Maltese) is not supported by ChatGPT
        vatDefault: 18,
        currencyCode: "EUR",
      },
      {
        countryCode: "NL",
        languageCodes: ["nl"],
        vatDefault: 9,
        currencyCode: "EUR",
      },
      {
        countryCode: "PT",
        languageCodes: ["pt-PT"],
        vatDefault: 13,
        currencyCode: "EUR",
      },
      {
        countryCode: "SK",
        languageCodes: ["sk"],
        vatDefault: 10,
        currencyCode: "EUR",
      }
    ];
  }

  // languages supported by F6ST
  public static getSupportedLanguages(): string[] {
    const additionalLanguages = ["zh", "ja", "ru", "ko", "pl", "uk", "ro", "tr", "hu"]; // Top tourist (who visit Europe) and most spoken other languages
    let languages = Array.from(new Set(this.getSupportedCountries().flatMap(country => country.languageCodes)));

    // Add top tourist languages
    additionalLanguages.forEach(lang => {
      if (!languages.includes(lang)) {
        languages.push(lang);
      }
    });

    // Ensure 'en' is included
    if (!languages.includes("en")) {
      languages.push("en");
    }

    return languages;
  }

  public static getDistanceMetricSystem(countryCode: string): MetricSystem {
    const countriesUsingMiles = ['US', 'GB', 'MM']; // Add more if necessary
    return countriesUsingMiles.includes(countryCode) ? MetricSystem.MILES : MetricSystem.KM;
  }

  // get displayable currency symbol
  public static getDisplayableCurrency(countryCode: string): string {
    const country = this.getSupportedCountry(countryCode);
    if (country) {
      switch (country.currencyCode) {
        case "EUR":
          return "€";
        // Add more cases for other currencies if needed
        default:
          return country.currencyCode;
      }
    }
    return "";
  }

  public static isLanguageSupported(languageCode: string): boolean {
    // handle the case if the language code is pt and not pt-PT
    if (languageCode === "pt") {
      languageCode = "pt-PT";
    }
    return this.getSupportedLanguages().includes(languageCode);
  }

  public static getSupportedPaymentMethods(countryCode: string): string[] {
    const supportedCountry = I18nUtil.getSupportedCountry(countryCode);
    const defaultPaymentMethods = ["card", "paypal"];
    if (supportedCountry && supportedCountry.additionalPaymentMethods) {
      return defaultPaymentMethods.concat(supportedCountry.additionalPaymentMethods);
    }
    return defaultPaymentMethods;
  }

  // get supported country by country code
  public static getSupportedCountry(countryCode: string): CountryData {
    const res = this.getSupportedCountries().find(c => c.countryCode === countryCode);
    if (!res) {
      throw new AppError(`Country ${countryCode} not found`);
    }
    return res;
  }
}

export const initI18n = () => {
  return i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      debug: false,
      interpolation: {
        escapeValue: false,
      },
      detection: {
        order: [
          'cookie',
          'localStorage',
          'sessionStorage',
          'navigator',
          'htmlTag',
          'path',
          'subdomain',
          'querystring'
        ],
        caches: ['cookie', 'localStorage'],
        cookieOptions: { path: '/', domain: '.f6st.com' },
      },
    })
    .then(() => {
      const detectedLanguage = i18n.language || 'en';
      if (i18n.language !== detectedLanguage) {
        i18n.changeLanguage(detectedLanguage);
      }
      if (typeof i18n.hasResourceBundle !== 'function') {
        throw new Error('i18n2 instance is not properly initialized');
      }
      Log.debug("i18next initialized, detected language", i18n.language);
    })
    .catch(error => {
      Log.error("Failed to initialize i18next", error);
      throw error; // Optionally handle the error further
    });
};

export type CountryData = {
  countryCode: string; // ISO 3166, uppercase
  languageCodes: string[]; // ISO 639-1, lowercase
  vatDefault: number; // usual VAT for food in restaurant
  currencyCode: string; // ISO 4217, uppercase 
  additionalPaymentMethods?: string[]; // additional payment methods on top of card and PayPal, optional
  openForRegistration?: boolean; // added flag for open for registration, optional
};
