import { CallerType, Customer, Log, QrCode, User } from 'base.f6st.com';
import { ApiUtil } from '../util/ApiUtil';
import { CustomerClient } from './CustomerClient';
import { UserClient } from './UserClient';
import { LoginUtil } from '../util/LoginUtil';

export class LoginClient {

  public static async isFirstAdminLogin(caller: CallerType, userId: string): Promise<boolean | undefined> {
    Log.debug('Loading user data by ID from DynamoDB', { userId });
    if (ApiUtil.isLocalApiAvailable()) {
      const LoginApi = await this.getLoginApi();
      return await LoginApi.isFirstAdminLogin(userId);
    } else {
      return await ApiUtil.postRequest<boolean | undefined>(caller, "login/isFirstAdminLogin", { userId });
    }
  }

  public static async loginOrder(caller: CallerType, qrCode: QrCode, languageCode: string): Promise<Customer | undefined> {
    Log.debug('Loading customer data by QR code from DynamoDB', { qrCode, languageCode });
    let response: { customer?: Customer, authorizationToken?: string };

    if (ApiUtil.isLocalApiAvailable()) {
      const LoginApi = await this.getLoginApi();
      response = await LoginApi.loginOrder(qrCode, languageCode);
    } else {
      response = await ApiUtil.postRequest<{ customer: Customer, authorizationToken?: string }>(caller, "loginOrder", { qrCode, languageCode });
    }

    if (response.authorizationToken) {
      LoginUtil.addTokenToStorage(caller, response.authorizationToken);
    }

    return response.customer;
  }

  public static async loginAdmin(caller: CallerType, userId: string, password: string): Promise<User | undefined> {
    Log.debug('Attempting to login user', { userId });
    let response: { user?: User, authorizationToken?: string };

    if (ApiUtil.isLocalApiAvailable()) {
      const LoginApi = await this.getLoginApi();
      response = await LoginApi.loginAdmin(userId, password);
    } else {
      response = await ApiUtil.postRequest<{ user: User, authorizationToken?: string }>(caller, "loginAdmin", { userId, password });
    }

    if (response.authorizationToken) {
      LoginUtil.addTokenToStorage(caller, response.authorizationToken);
    }

    return response.user;
  }

  public static async loginRoot(caller: CallerType, userId: string, password: string): Promise<boolean> {
    Log.debug('Attempting root login', { userId });

    let response: { success: boolean, authorizationToken?: string } = { success: false };

    if (ApiUtil.isLocalApiAvailable()) {
      const LoginApi = await this.getLoginApi();
      response = await LoginApi.loginRoot(userId, password);
    } else {
      response = await ApiUtil.postRequest<{ success: boolean, authorizationToken?: string }>(caller, "loginRoot", { userId, password });
    }

    if (response.authorizationToken) {
      LoginUtil.addTokenToStorage(caller, response.authorizationToken);
    }

    return response.success;
  }

  private static async getLoginApi() {
    // @ts-ignore: Dynamic import may not resolve during production build
    return (await import('api.f6st.com')).LoginApi;
  }

}
