import { Customer } from "base.f6st.com";

const DISCOUNT_STORAGE_KEY_PREFIX = 'comeBackDiscount_';
const DISCOUNT_ELIGIBILITY_DURATION = 60 * 24 * 60 * 60 * 1000; // 60 days in milliseconds
const MIN_ELIGIBILITY_DURATION = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

export class DiscountUtil {
  // Generate a unique storage key for a customer
  private static getStorageKey(customerId: string): string {
    return `${DISCOUNT_STORAGE_KEY_PREFIX}${customerId}`;
  }

  // Save discount eligibility to local storage
  public static saveDiscount(customerId: string): void {
    const discountData = {
      customerId,
      timestamp: Date.now()
    };
    const storageKey = this.getStorageKey(customerId);
    localStorage.setItem(storageKey, JSON.stringify(discountData));
  }

  // Check if the customer is eligible for the discount and return with it
  public static getDiscount(customer: Customer): number {

    const discountPercentage = customer.businessSettings.discountPercentage &&
      customer.businessSettings.discountPercentage > 0 ? customer.businessSettings.discountPercentage : undefined;

    if (!discountPercentage) return 0;

    const storageKey = this.getStorageKey(customer.id);
    const discountData = localStorage.getItem(storageKey);
    if (discountData) {
      const { customerId: storedCustomerId, timestamp } = JSON.parse(discountData);
      if (storedCustomerId === customer.id) {
        const currentTime = Date.now();
        if (
          currentTime - timestamp >= MIN_ELIGIBILITY_DURATION &&
          currentTime - timestamp <= DISCOUNT_ELIGIBILITY_DURATION
        ) {
          return discountPercentage;
        }
      }
    }
    return 0;
  }
}
