import { CallerType, Log, ProductFilter, ProductRecommendation } from 'base.f6st.com';
import { ApiUtil } from '../util/ApiUtil';

export class ProductAssistantClient {

  public static async getRecommendation(
    caller: CallerType,
    currentBasket: string,
    customerLanguage: string,
    products: string,
    filter?: ProductFilter,
    orderHistory?: string
  ): Promise<ProductRecommendation | undefined> {
    Log.debug(`Requesting recommendation for language: ${customerLanguage}`);

    if (ApiUtil.isLocalApiAvailable()) {
      const ProductAssistantApi = await this.getProductAssistantApi();
      return await ProductAssistantApi.getRecommendation(currentBasket, customerLanguage, products, filter, orderHistory);
    } else {
      return await ApiUtil.postRequest<ProductRecommendation | undefined>(caller, 'productAssistant/getRecommendation', {
        currentBasket,
        customerLanguage,
        products,
        filter,
        orderHistory,
      });
    }
  }

  private static async getProductAssistantApi() {
    // @ts-ignore: Dynamic import may not resolve during production build
    return (await import('api.f6st.com')).ProductAssistantApi;
  }
}
