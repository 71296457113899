import { MouseEvent, useMemo } from 'react';
import { Grid, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import { useAppContext } from '../components/AppProvider';
import { Product, OrderItem, ProductsTextsUtil, IDUtil, OrderUtil, AppError } from 'base.f6st.com';
import { I18nUtil, StandardButton, Config } from 'common.f6st.com';

export enum CardUseCase {
  HOMEPAGE,
  BASKETPAGE,
  ORDERPAGE,
}

type ProductCardProps = {
  product: Product;
  useCase: CardUseCase;
  orderItem?: OrderItem;
  onBasketChange?: () => void; // Adding the onBasketChange parameter
};

export const ProductCard = ({ product, orderItem, useCase, onBasketChange }: ProductCardProps) => {
  const customer = useAppContext().getCustomer();
  const basket = useAppContext().basket;
  const currency = I18nUtil.getDisplayableCurrency(customer.businessSettings.countryCode);
  const { i18n } = useTranslation();
  const txt = useMemo(() => new ProductsTextsUtil(customer, i18n.language), [customer, i18n.language]);
  let currentOrderItem: OrderItem;

  if (useCase === CardUseCase.HOMEPAGE && product) {
    currentOrderItem = {
      id: IDUtil.getShortId(),
      product: product,
      quantity: 1,
      extraIds: [],
      vatPercentage: OrderUtil.getProductVATPercentage(product, customer),
    };

    if (product.sizes && product.sizes.length > 1) {
      currentOrderItem.sizeId = product.sizes[0].id; // Select the first size by default
    }
  } else if (orderItem) {
    currentOrderItem = orderItem;
  } else {
    throw new AppError('Invalid parameters for ProductCard component');
  }

  const productPriceString = OrderUtil.getProductTotalAmount(currentOrderItem.product, currency, currentOrderItem.sizeId).amountString;
  const hasMultipleSizes = currentOrderItem.sizeId && product.sizes && product.sizes.length > 1;
  const selectedSize = hasMultipleSizes ? product.sizes?.find(size => size.id === currentOrderItem.sizeId) : undefined;
  const selectedExtras = currentOrderItem.extraIds ? currentOrderItem.extraIds.map(extraId => product.extras.find(extra => extra.id === extraId)) : [];

  const handleAddToBasket = (e: MouseEvent) => {
    e.preventDefault();
    basket.addItem(currentOrderItem);
    toast.success(`Product has been added to the basket`);
    if (onBasketChange) onBasketChange();
  };

  const handleRemoveFromBasket = (e: MouseEvent) => {
    e.preventDefault();
    basket.removeFromBasket(currentOrderItem.id);
    if (onBasketChange) onBasketChange();
  };

  const getActionButton = () => {
    switch (useCase) {
      case CardUseCase.HOMEPAGE:
        return <StandardButton text='+' action={handleAddToBasket} style={{ position: 'absolute', top: '-8px', right: '-8px', width: '40px', height: '40px', borderRadius: '36px' }} />;
      case CardUseCase.BASKETPAGE:
        return <StandardButton text='-' action={handleRemoveFromBasket} style={{ position: 'absolute', top: '-8px', right: '-8px', width: '40px', height: '40px', borderRadius: '36px' }} />;
      default:
        return null;
    }
  };

  const cardContent = (
    <Card sx={{ display: 'flex', justifyContent: 'space-between', position: 'relative', overflow: 'visible' }}>
      <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography noWrap component="div" variant="h5" fontSize={16} sx={{ textDecoration: '' }}>
          {useCase === CardUseCase.HOMEPAGE ? txt.get(product.nameId) : `${currentOrderItem.quantity} x ${txt.get(product.nameId)}`}
        </Typography>
        {useCase === CardUseCase.HOMEPAGE && (
          <Typography variant="subtitle1" color="text.secondary" fontSize={10} maxHeight='70px' sx={{ overflow: 'hidden' }}>
            {txt.get(product.descriptionId)}
          </Typography>
        )}
        {useCase !== CardUseCase.HOMEPAGE && (
          <Typography variant="subtitle1" color="text.secondary">
            {selectedSize ? txt.get(selectedSize.nameId) : ''}
            {selectedExtras && selectedExtras.length > 0 && ', '}
            {selectedExtras.map((extra, index) => extra ? `${index > 0 ? ', ' : ''} + ${txt.get(extra.nameId)}` : '').join('')}
          </Typography>
        )}
        <Typography variant="subtitle1" color="text.secondary" marginTop={2}>
          {useCase === CardUseCase.HOMEPAGE && product.sizes && product.sizes.length > 1
            ? `${product.sizes[0].price}-${product.sizes[product.sizes.length - 1].price}${currency}`
            : productPriceString}
        </Typography>
      </CardContent>

      <CardMedia
        component="img"
        sx={{ width: 140, height: 140, borderRadius: '5px' }}
        image={Config.getImageUrl(customer.id, product.imgId)}
        alt={txt.get(product.nameId)}
      />

      {getActionButton()}
    </Card>
  );

  return (
    <Grid item xs={12} sm={6} md={6} lg={4}>
      {useCase !== CardUseCase.ORDERPAGE ? (
        <Link
          style={{ textDecoration: 'none' }}
          to={`/product/${product.id}`}
          state={{ orderItem: currentOrderItem }}
        >
          {cardContent}
        </Link>
      ) : (
        cardContent
      )}
    </Grid>
  );
};
