import { Config } from "common.f6st.com/src/util/Config";
import { BuyerIdStorage } from "./BuyerIdStorage";
import { I18nUtil } from "common.f6st.com/src/util/I18nUtil";
import { Customer, IDUtil, Log, Order, OrderItem, OrderStatus, OrderUtil, PaymentStatus, ProductsTextsUtil, QrCode } from "base.f6st.com";

export class BasketData {
    private customer: Customer;
    private qrCode: QrCode;
    private order?: Order;

    constructor(customer: Customer, qrCode: QrCode) {
        this.customer = customer;
        this.qrCode = qrCode;
    }

    private init(): Order {
        this.order = {
            id: '',
            customerId: this.customer.id,
            buyerId: BuyerIdStorage.getId(),
            currency: I18nUtil.getDisplayableCurrency(this.customer.businessSettings.countryCode),
            orderStatus: OrderStatus.NEW,
            qrCode: this.qrCode,
            serviceFeePercentage: this.customer.businessSettings.serviceFeePercentage,
            priority: false,
            testMode: this.customer.businessSettings.testMode,
            paymentStatus: PaymentStatus.NOT_PAID, // will be changed later
            date: new Date(), // must be actualized later
            items: [], // will be added later with the addToBasket
            discountPercentage: undefined, // will be set later
            tipPercentage: undefined, // will be set later
            deliveryFee: 0, // will be set later
            texts: {}, // will be set later
            totalAmount: 0, // will be set later
            deliveryDetails: undefined, // will be set later
            paymentMethod: undefined, // will be set later
        };
        return this.order;
    }

    getOrder(): Order {
        if (!this.order) {
            this.order = this.init();
        }
        return this.order;
    }


    finalize( txt: ProductsTextsUtil, paymentStatus: PaymentStatus, paymentMethod?: string): Order {
        const order = this.getOrder();
        order.id = IDUtil.getShortId();

        const { total, deliveryFee } = OrderUtil.getOrderAmount(order);
        order.totalAmount = total;
        order.deliveryFee = deliveryFee;
 
        // date
        order.date = new Date();
        order.paymentStatus = paymentStatus;
        // actualize texts 
        const texts: { [key: string]: string } = {};
        order.items.forEach(item => {
            texts[item.product.nameId] = txt.get(item.product.nameId);
            if (item.sizeId) {
                const size = item.product.sizes?.find(size => size.id === item.sizeId);
                if (size) {
                    texts[size.nameId] = txt.get(size.nameId);
                }
            }
            if (item.extraIds) {
                item.extraIds.forEach(extraId => {
                    const extra = item.product.extras.find(extra => extra.id === extraId);
                    if (extra) {
                        texts[extra.nameId] = txt.get(extra.nameId);
                    }
                });
            }
        });

        order.texts = texts;
        this.getOrder().paymentMethod = paymentMethod;
        this.order = undefined; // new order will be initalized with the new getOrder()

        return order;

    }

    isInBasket(orderItemId: string): boolean {
        return this.getOrder().items.some(({ id }) => id === orderItemId);
    }

    getBasketSize(): number {
        return this.getOrder().items.reduce((total, item) => total + (item.quantity || 1), 0);
    }

    addItem(orderItem: OrderItem): void {

        Log.debug('Adding to basket', orderItem);

        if (orderItem.quantity < 1) { // remove item from basket if quantity is 0
            this.getOrder().items = this.getOrder().items.filter(({ id }) => id !== orderItem.id);
        } else {
            const similarOrderItem: OrderItem | undefined = this.getOrder().items.find((item) =>
                item.id !== orderItem.id &&
                item.product.id === orderItem.product.id &&
                item.sizeId === orderItem.sizeId &&
                JSON.stringify(item.extraIds) === JSON.stringify(orderItem.extraIds)
            );

            if (similarOrderItem) { // just increment the quantity if the product is already in the basket
                orderItem.quantity += similarOrderItem.quantity;
                this.removeFromBasket(similarOrderItem.id);
            }
            this.removeFromBasket(orderItem.id);
            this.getOrder().items.push(orderItem); // add the new item
        }

    }

    removeFromBasket(orderItemId: string): void {
        this.getOrder().items = this.getOrder().items.filter(({ id }) => id !== orderItemId);
    }

    clearBasket(): void {
        this.getOrder().items = [];
    }

    isEmpty(): boolean {
        return !this.order || this.getOrder().items.length === 0;
    }
}
