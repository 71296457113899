import { IDUtil } from "base.f6st.com";


export class BuyerIdStorage {
    private static localStorageKey = 'buyerId';
  
    public static getId(): string {
      let buyerId = localStorage.getItem(BuyerIdStorage.localStorageKey);
  
      if (!buyerId) {
        buyerId = IDUtil.getShortId();
        localStorage.setItem(BuyerIdStorage.localStorageKey, buyerId);
      }
  
      return buyerId;
    }
  }