import { useEffect, useState, useRef } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { I18nUtil } from '../util/I18nUtil';

type LanguageSelectorProps = {
  i18n: any;
  small?: boolean;
  onLanguageChange?: (newLanguage: string) => Promise<void>;
};

export const LanguageSelector = ({ i18n, small = false, onLanguageChange }: LanguageSelectorProps) => {
  const [language, setLanguage] = useState(i18n.language);
  const [supportedLanguages, setSupportedLanguages] = useState<string[]>([]);
  const maxWidthRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const languages = I18nUtil.getSupportedLanguages();
    setSupportedLanguages(languages);

    const handleLanguageChange = (lng: string) => setLanguage(lng);

    // Listen for language changes
    i18n.on('languageChanged', handleLanguageChange);

    // Clean up the event listener on component unmount
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  useEffect(() => {
    if (maxWidthRef.current) {
      const options = maxWidthRef.current.querySelectorAll('.MuiAutocomplete-option');
      const maxWidth = Array.from(options).reduce((max, el) => Math.max(max, el.scrollWidth), 0);
      maxWidthRef.current.style.width = `${maxWidth + 32}px`; // Add some padding
    }
  }, [supportedLanguages]);

  const handleChange = async (event: any, newLanguage: string | null) => {
    if (newLanguage) {
      if (onLanguageChange) {
        await onLanguageChange(newLanguage); // Call the optional callback if passed
      }

      i18n.changeLanguage(newLanguage); // Change language by default
    }
  };

  return (
    <FormControl variant="outlined" ref={maxWidthRef} sx={{ minWidth: small ? 190 : undefined }}>
      <Autocomplete
        id="language-select"
        value={language}
        onChange={handleChange}
        options={supportedLanguages}
        getOptionLabel={(option) => {
          const label = i18n.t(`language.${option}`);
          return label;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={small ? "" : "Select Language"}
            variant="outlined"
            sx={small ? {
              '& .MuiOutlinedInput-root': {
                padding: '2px 8px',
              },
              '& .MuiOutlinedInput-input': {
                fontSize: '0.875rem',
              },
              '& .MuiAutocomplete-endAdornment': {
                display: 'none',
              },
            } : {
              '& .MuiAutocomplete-endAdornment': {
                display: 'none',
              },
            }}
          />
        )}
      />
    </FormControl>
  );
};