import { CallerType, Log } from 'base.f6st.com';
import { ApiUtil } from '../util/ApiUtil';

export class MailClient {

  public static async sendForgottenUserCode(caller: CallerType, email: string, subject: string, body: string): Promise<void> {
    Log.debug('Sending forgotten user code email', { email, subject });
    if (ApiUtil.isLocalApiAvailable()) {
      const MailApi = await this.getMailApi();
      await MailApi.sendForgottenUserCode(email, subject, body);
    } else {
      await ApiUtil.postRequest<void>(caller, "mail/sendForgottenUserCode", { email, subject, body });
    }
  }

  public static async isEmailExists(caller: CallerType, email: string): Promise<boolean> {
    Log.debug('Checking if email exists', { email });
    if (ApiUtil.isLocalApiAvailable()) {
      const MailApi = await this.getMailApi();
      return await MailApi.isEmailExists(email);
    } else {
      return await ApiUtil.postRequest<boolean>(caller, "mail/isEmailExists", { email });
    }
  }

  public static async sendFeedback(caller: CallerType, subject: string, body: string, attachmentBase64?: string, attachmentName?: string): Promise<void> {
    Log.debug('Sending feedback email', { subject });
    if (ApiUtil.isLocalApiAvailable()) {
      const MailApi = await this.getMailApi();
      await MailApi.sendFeedback(subject, body, attachmentBase64, attachmentName);
    } else {
      await ApiUtil.postRequest<void>(caller, "mail/sendFeedback", { subject, body, attachmentBase64, attachmentName });
    }
  }

  public static async sendEmail(caller: CallerType, email: string, subject: string, body: string, attachmentBase64?: string, attachmentName?: string): Promise<void> {
    Log.debug('Sending email', { email, subject });
    if (ApiUtil.isLocalApiAvailable()) {
      const MailApi = await this.getMailApi();
      await MailApi.sendEmail(email, subject, body, attachmentBase64, attachmentName);
    } else {
      await ApiUtil.postRequest<void>(caller, "mail/sendEmail", { email, subject, body, attachmentBase64, attachmentName });
    }
  }

  private static async getMailApi() {
    // @ts-ignore: Dynamic import may not resolve during production build
    return (await import('api.f6st.com')).MailApi;
  }
}
