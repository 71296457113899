import { Log, Order } from 'base.f6st.com';

const ORDER_STORAGE_KEY_PREFIX = 'orders_';

export class OrderStorage {
  private static getOrderStorageKey(customerId: string): string {
    return `${ORDER_STORAGE_KEY_PREFIX}${customerId}`;
  }

  static save(order: Order, customerId: string): void {
    const orders = this.getAll(customerId);
    orders.unshift(order);
    localStorage.setItem(this.getOrderStorageKey(customerId), JSON.stringify(orders));
  }

  static getAll(customerId: string, orderNumber?: number): Order[] {
    try {
      const orders = JSON.parse(localStorage.getItem(this.getOrderStorageKey(customerId)) ?? '[]');
      const parsedOrders = orders.map((order: Order) => ({
        ...order,
        date: new Date(order.date),
        refundDate: order.refundDate ? new Date(order.refundDate) : null
      }));

      if (orderNumber !== undefined) {
        return parsedOrders.slice(0, orderNumber);
      }

      return parsedOrders;
    } catch (error) {
      Log.debug('Error parsing orders, clearing local storage', error);
      this.clearAll(customerId);
      return [];
    }
  }

  static clearAll(customerId: string): void {
    localStorage.setItem(this.getOrderStorageKey(customerId), JSON.stringify([]));
  }

  static get(hours: number, customerId: string): Order[] {
    const orders = this.getAll(customerId);
    const now = new Date();
    const milliseconds = hours * 60 * 60 * 1000;
    return orders.filter((order) => {
      const diff = now.getTime() - order.date.getTime();
      return diff < milliseconds;
    });
  }
}
