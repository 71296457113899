import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router";
import { Container } from "@mui/material";
import CuystomAppBar from "../components/CustomAppBar";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useAppContext } from '../components/AppProvider';
import { ProductDetailsCard } from '../components/ProductDetailsCard';
import { OrderItem, OrderUtil } from 'base.f6st.com';
import { I18nUtil, StandardButton } from 'common.f6st.com';

export const ProductDetailPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { basket } = useAppContext();
  const customer = useAppContext().getCustomer();
  const orderItem: OrderItem = location.state?.orderItem;
  const orderItemInBasket = orderItem ? basket.isInBasket(orderItem.id) : false;
  const currency = I18nUtil.getDisplayableCurrency(customer.businessSettings.countryCode);
  const orderItemAmountString = orderItem ? OrderUtil.getOrderItemTotalAmount(orderItem, currency).amountString : 0;
  const [buttonLabel, setButtonLabel] = useState('');

  useEffect(() => {
    if (orderItem) {
      const label = orderItemInBasket
        ? `${t("buttons.save")} (${orderItemAmountString})`
        : `${t("buttons.addToBasket")} (${orderItemAmountString})`;
      setButtonLabel(label);
    }
  }, [orderItem, orderItemInBasket, orderItemAmountString, t, customer]);

  const handleSaveChanges = () => {
    if (orderItem) {
      if (orderItemInBasket)
        toast.success(`Product has been saved to the basket`);
      else
        toast.success(`Product has been added to the basket`);

      basket.addItem(orderItem);
      if (basket.isEmpty() || !orderItemInBasket) {
        navigate('/');
      } else {
        navigate('/basket');
      }
    }
  };

  const handleDetailsChange = (updatedOrderItem: OrderItem) => {
    const newLabel = basket.isInBasket(updatedOrderItem.id)
      ? `${t("buttons.save")} (${OrderUtil.getOrderItemTotalAmount(updatedOrderItem,currency).amountString})`
      : `${t("buttons.addToBasket")} (${OrderUtil.getOrderItemTotalAmount(updatedOrderItem,currency).amountString})`;
    setButtonLabel(newLabel);
  };

  return (
    <>
      <CuystomAppBar backButton />
      <Container>
        {orderItem && (
          <ProductDetailsCard
            orderItem={orderItem}
            onDetailsChange={handleDetailsChange}
          />
        )}
        <StandardButton
          text={buttonLabel}
          action={handleSaveChanges}
          style={{ width: '80%', height: '56px', position: 'fixed', bottom: '60px', left: '50%', transform: 'translateX(-50%)', backgroundColor: '#e84b4bee' }}
        />
      </Container>
    </>
  );
};